import React, { Component } from 'react';
import config from '../../../config';
import Tracking from 'client/tracking';
import { STEPS } from '../constants/steps';

export default class MyPhotoApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: STEPS.LEGAL
        };

        this.uploadInput = React.createRef();

        this.displayError = this.displayError.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        const { params = {} } = this.props;
        const customPageName = ['myphoto']
            .join('|')
            .replace(/\|*$/, '');
        Tracking.pageLoad({
            area: 'help',
            locationParams: {
                ...params,
                customPageName
            }
        });
    }

    onClick() {
        Tracking.clickEvent(this.uploadInput.current);
        this.uploadInput.current.click();
    }

    renderUpload({ text }) {
        return (
            <div>
                <button className="u-margin-top c-btn c-btn--primary" onClick={() => this.onClick()}>{text}</button>
                <input ref={this.uploadInput} key="input" type="file" style={{ display: 'none' }} onChange={(e) => this.uploadFile(e)} />
                <p className="c-text-smallprint" style={{ marginBottom: '0' }}>(Maximum size: 10 MB. Images should be jpg/jpeg or png format)</p>
            </div>
        );
    }

    displayError() {
        this.setState({
            step: STEPS.ERROR
        });
    }

    uploadFile(e) {
        const file = e.target.files[0];

        if (file.size > 1024 * 1024 * 10) { // 10 MB
            return this.setState({ maxFileSizeExceeded: true });
        }

        this.setState({
            maxFileSizeExceeded: false,
            step: STEPS.UPLOADING
        });

        fetch(`${config.endpoints.myPhotoApi}/upload-image`, {
            method: 'POST',
            mode: 'cors',
            body: file
        })
            .then(response => response.json())
            .then(json => this.setState({ step: STEPS.SUCCESS, photoId: json.photoId }))
            .catch(this.displayError);
    }

    renderLegal() {
        return (
            <div>
                <h2 className="c-heading-charlie">Legal Disclaimer</h2>
                <p>
                        Please ensure any photos taken are close ups of your Broadband home set up.
                </p>
                <p>
                    <strong>Don't include any personal information, property or individuals.</strong>
                </p>
                <p>
                        In submitting this image, you accept all rights to the image are given up.
                </p>
                <p>
                        This image will be stored for no longer than 5 days. It will not be associated with your Sky account.
                </p>
                <p>
                        Are you happy to proceed?
                </p>

                {this.renderUpload({ text: 'Yes, upload photo' })}
            </div>);
    }

    renderSuccess() {
        const { params = {} } = this.props;

        Tracking.pageLoad({
            area: 'help',
            locationParams: {
                ...params,
                customPageName: 'myphoto:success'
            }
        });

        return (
            <div>
                <h2 className="c-heading-charlie">Thanks for uploading</h2>
                <p>Please quote this reference number to the Sky Advisor.</p>
                <p className="c-heading-alpha">{this.state.photoId}</p>
                {this.renderUpload({ text: 'Upload another photo' })}
            </div>
        );
    }

    renderError() {
        const { params = {} } = this.props;

        Tracking.pageLoad({
            area: 'help',
            locationParams: {
                ...params,
                customPageName: 'myphoto:error'
            }
        });

        return (
            <div>
                <h2 className="c-heading-charlie">Error Uploading Image</h2>
                <p>
                    Apologies, there has been an error uploading your image
                    please <a className="c-link" onClick={() => this.setState({ step: STEPS.LEGAL })}>try again</a>.
                </p>
            </div>
        );
    }

    render() {
        return (
            <div className="o-container u-padding-top">
                <h1 className="c-heading-alpha">My Photo</h1>
                {this.state.step === STEPS.LEGAL && this.renderLegal()}
                {this.state.step === STEPS.UPLOADING && <strong className="c-spinner">Loading…</strong>}
                {this.state.step === STEPS.SUCCESS && this.renderSuccess()}
                {this.state.step === STEPS.ERROR && this.renderError()}
                {this.state.maxFileSizeExceeded ? <p className="my-photo__file-size-error">Please choose a smaller image</p> : null}
            </div>
        );
    }
}
