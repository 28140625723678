export default () => ({
    routes: {
        myphoto: {
            path: '/myphoto',
            area: 'myphoto',
            app: 'myphoto',
            mastheadVersion: 'help-and-support',
            mastheadUser: 'helpandsupport'
        }
    }
});
